export const patientES: any = {
  mainMenu: "Main Navigational Menu Consumer GCSO - Global",
  footerMenu: "Footer Navigation Menu Consumer GCSO - Global",
  banner: "Banner - HTML - Emerging Science - DTC - US Global",
  footer: "Retina Global Footer Consumer - US - Global",
  exitPopup: "Retina Canada Exit Popup New - Global",
  hcpSwitcher: "HCP Switcher Modal Pop Up - Global",
  topNav: "Top Navigational Menu Consumer US - Global",
  faqSection: "Emerging Science FAQ Section HTML - Global",
  learnMore: "Emerging Science Learn More HTML - Global",
  whenYouTest: "Emerging Science When You Test Updated- Global",
  scienceAdvancing: "Emerging Science Science is advancing - Global",
  footerCallout: "Footer Callout Emerging science - HTML DTC US Global",
  siteLogo: "Site logos - Global",
  redirectLangUrl: {
    "redirectUrl": {
      "en": "/emerging-science",
      "es": "/es/emerging-science"
    }
  },
  gtmBackToTopLabel: "Back to top - emerging science",
  footerClassName: "emerging-science-footer"
}
