import React from 'react'
import Layout from "@retina-packages/retina-theme-bootstrap/packages/components/layout"
import { graphql } from 'gatsby'
import { PageWrapper, EmergingScienceWrapper } from '@retina-packages/retina-theme-bootstrap/packages/css/CommonStyle'
import MetaTagContainer from '@retina-packages/retina-theme-bootstrap/packages/containers/MetaTagContainer'
import HTMLRenderer from '@retina-packages/retina-theme-bootstrap/packages/components/HTMLRenderer'
import { Props, PageQueryResult } from '@retina-packages/retina-theme-bootstrap/packages/types/CommonTypes'
import { exitPopDataType } from '@retina-packages/retina-theme-bootstrap/packages/types/ExitPopUpType'
import { reusableDataBuilders } from '@retina-packages/retina-theme-bootstrap/packages/helpers'
import { UsHeaderContainer, HomePageFooterContainer, UsEmergingScienceWrapperContainer, RepWrapper } from "../../../css/common/style"
import retinaConfig from '../../../utils/retinaConfigs'
import { FooterCalloutBlock } from '@retina-packages/retina-theme-bootstrap/packages/containers/FooterNavigationCallOutContainer/style'
import { EmerFooterBlock, InfoBoxWrapper, WhenYoutest, CommonParagraphEmergingScience } from './styles'
import { MobileBanner } from '@retina-packages/retina-theme-bootstrap/packages/components/styles/styles'
import { deriveSiteLogo, deriveSiteMenus, fullHTMLDataBuilder } from '../../../utils'
import { patientES } from './constants'

/**
 * Rendering page template for Emerging science
 *
 * @param props Props
 * @returns
 */
const EmergingScienceTemplate = (props: Props) => {

  const pgProp: PageQueryResult = props.data
  const blocks = pgProp.nodePage.relationships.field_paragraphs_gcso
  const pgContextsES = props.pageContext
  const htmlStaticFilesES = {
    nonSVGImages: pgContextsES.nonSVGImages,
    svgMediaImages: pgContextsES.svgMediaImages,
    allMediaDocument: pgContextsES.documents
  }

  const { mainMenu, footerMenu } = deriveSiteMenus({ pgProp, siteMainMenu: patientES.mainMenu, siteFooterMenu: patientES.patFooterMenu })
  const audience = pgProp.nodePage.relationships.field_general_tags_gcso[0].name
  const bannerTitle = ""
  const carouselTitle = ""
  const footerTitle = patientES.footer
  const exitPopupTitle = patientES.exitPopup
  const hcpSwitcherTitle = patientES.hcpSwitcher
  const topNavTitle = patientES.topNav
  const {
    footerText,
    exitData,
    hcpSwitcher,
    topNavs
  }: {
    footerText: string
    exitData: exitPopDataType
    hcpSwitcher: exitPopDataType
    topNavs: string
  } = reusableDataBuilders({ blocks, bannerTitle, carouselTitle, audience, footerTitle, exitPopupTitle, hcpSwitcherTitle, topNavTitle })

  const siteLogos = deriveSiteLogo({ blocks, title: patientES.siteLogo })
  const bannerHTML = fullHTMLDataBuilder({ blocks, title: patientES.banner })

  const faqSectionHTML = fullHTMLDataBuilder({ blocks, title: patientES.faqSection })
  const learnMoreHTML = fullHTMLDataBuilder({ blocks, title: patientES.learnMore })
  const whenYouTestHTML = fullHTMLDataBuilder({ blocks, title: patientES.whenYouTest })
  const scienceAdvancingHTML = fullHTMLDataBuilder({ blocks, title: patientES.scienceAdvancing })
  const footerCalloutHTML = fullHTMLDataBuilder({ blocks, title: patientES.footerCallout })

  return (
    <>
      <UsHeaderContainer>
        <HomePageFooterContainer className="homepagefooterontainer">
          <UsEmergingScienceWrapperContainer>
            <EmergingScienceWrapper>
              {props.pageContext !== null && props.pageContext.metaInfo !== null && (
                <MetaTagContainer metaData={props.pageContext.metaInfo} />
              )}
              <Layout
                title={"siteTitle"}
                location={props.location}
                data={mainMenu}
                mdata={footerMenu}
                footerData={footerText}
                audience={audience}
                exitPopData={exitData}
                hcplinks={hcpSwitcher}
                topNavigation={topNavs}
                staticMediaFiles={htmlStaticFilesES}
                siteLogos={siteLogos}
                backToTopContent={retinaConfig.backToTopContent}
                gtmBackToTopLabel={patientES.gtmBackToTopLabel}
                modalCloseButtonLabel={retinaConfig.modalCloseButtonLabel}
                modalCloseButtonGTMLabel={retinaConfig.modalCloseButtonGTMLabel}
                hcpValidate={retinaConfig.hcpValidate}
                hcpHomeUrl={retinaConfig.hcpHomeUrl}
                preIndexUrl={retinaConfig.preIndexUrl}
                hcpPrefix={retinaConfig.hcpPrefix}
                siteHomeUrl={retinaConfig.siteHomeUrl}
                footerClassName={patientES.footerClassName}
                cpraFooter={true}
                cpraFooterEnv={process.env.CPRA_FOOTER_ENV}
                cpraOT={true}
                cpraOTEnv={process.env.OT_ENV}
                cpraOTuuid={process.env.OT_UUID}
                languageConfig={{ ...retinaConfig.langConfig, ...patientES.redirectLangUrl }}
                {... { ...retinaConfig.layoutAttrs.dtcAttrs, ...retinaConfig.layoutAttrs.commonAttrs }}
              >
                <PageWrapper className='pagewrapper pagewrapper-emerging'>
                  <MobileBanner className="mobile-banner clinical-trials-banner">
                    <HTMLRenderer data={htmlStaticFilesES} html={bannerHTML} tagName='section' className='internal-banner o-column--full' simpleImage={true} />
                  </MobileBanner>
                  <RepWrapper className='o-container'>
                    <HTMLRenderer data={htmlStaticFilesES} html={faqSectionHTML} tagName='div' className='o-inner' />
                  </RepWrapper>
                  <InfoBoxWrapper className='infobox-text o-column--full infobox-text-reduce emerging-science-learn-more'>
                    <HTMLRenderer data={htmlStaticFilesES} html={learnMoreHTML} tagName='div' className='o-container' />
                  </InfoBoxWrapper>
                  <CommonParagraphEmergingScience>
                    <HTMLRenderer data={htmlStaticFilesES} html={scienceAdvancingHTML} />
                  </CommonParagraphEmergingScience>
                  <WhenYoutest className='o-container emerging-science-when-you-test'>
                    <HTMLRenderer data={htmlStaticFilesES} html={whenYouTestHTML} tagName='div' />
                  </WhenYoutest>
                  <EmerFooterBlock className='emr-footer-block'>
                    <FooterCalloutBlock className='o-container padding-cards-fix home-page-footer-us'>
                      <HTMLRenderer html={footerCalloutHTML} data={htmlStaticFilesES} tagName='div' className="footer-block footer-block-bg" />
                    </FooterCalloutBlock>
                  </EmerFooterBlock>
                </PageWrapper>
              </Layout>
            </EmergingScienceWrapper>
          </UsEmergingScienceWrapperContainer>
        </HomePageFooterContainer>
      </UsHeaderContainer>
    </>
  )
}

export default EmergingScienceTemplate

export const pageQuery = graphql`
  query($id: Int!) {
    nodePage(drupal_internal__nid: {eq: $id}) {
      relationships {
        ... EmergingSciencePageQuery
      }
    }
  }
`