import styled from 'styled-components'
import { device } from '@retina-packages/retina-theme-bootstrap/packages/css/device'

export const EmerFooterBlock = styled.div`
	.footer-block {
		&.footer-block-bg {
			background-color: transparent;
			padding-top: 0;
			.footer-block--row {
				.footer-inner-block {
					img {
						@media ${device.laptopMedium} {
							padding: 16px 0;
							height: auto;
						}

					}
				}
			}
		}

		.footer-block--row {
			@media ${device.laptopMedium} {
				.text-button-wrap {
					a {
						margin-left: 0;
						margin-top: 15px;
					}
				}
			}

			.footer-inner-block {
				padding: 16px 16px 20px;

				@media ${device.ipadLandscapemin} {
					padding: 32px 24px 120px;
					width: calc(50% - 15px);
				}
				@media ${device.laptopMedium} {
					&:last-child {
						margin-top: 15px;
					}
				}
				span {
					a {
						&:after {
							margin-top: 7px !important;
						}
						font-size: 1.8rem ;
						font-weight: 500;
						line-height: 2.6rem;

						@media ${device.ipadLandscapemin} {
							font-size: 2.2rem;
							line-height: 2.4rem;
 						}

					}
					&.text-para-wrap {
						font-family: 'Gotham-Book', Arial, Helvetica, sans-serif;
					}
				}

				&:before {
					background: linear-gradient(90deg, #8000be 0%, #8000be 45%, #594dc6 100%);
				}

				h3
				{
				&.o-common-title {
					font-size: 2.4rem;
					line-height: 1.2;
    			font-weight: 700;
					margin-bottom: 0;

					@media ${device.laptopMedium} {
						font-size: 2rem;
						line-height: 1.2;
					}
					@media ${device.ipadLandscapemin} {
						font-size: 2.7rem;
						line-height: 1.41;
					}
				}
				}
			}
		}
	}

	&.gen-footer-block {
		.footer-block {
			&.footer-block-bg {
				background: transparent;
				padding-top: 0;
			}
		}
	}

	&.imp-gens {
		.footer-block {
			&.footer-block-bg {
				background: transparent;
				padding-top: 0;

				.footer-inner-block {
					&:first-child {
						&:before {
							content: "";
							background: linear-gradient(90deg, #8000be 0%, #8000be 45%, #594dc6 100%);
							width: 100%;
							height: 16px;
							position: absolute;
							top: 0;
							left: 0;
							border-radius: 5px 5px 0 0;
						}
					}

					&:last-child {
						&:before {
							background: linear-gradient(90deg, #3398ce 0%, #3398ce 25%, #17d0d4 74%, #06f2d7 100%);
						}
					}
				}
			}
		}
	}
`;

export const InfoBoxWrapper = styled.div`
	&.infobox-text-reduce {
		margin: 10px 0 194px 0px;

	}

	&.o-column--full {
    width: 100%;
	}

	&.infobox-text {
    	position: relative;

		&:before {
			content: "";
			background-color: #00FFD9;
			height: 170px;
			position: absolute;
			left: 0;
			top: 85px;
			width: 100%;

			@media ${device.laptopPros} {
				top: 140px !important;
			}

			@media ${device.laptopMedium} {
				height: 200px;
				top: 60px;
			}
		}

		.o-container {
			max-width: 1208px;
			margin: 0 auto;
			padding: 0 2rem;

			@media ${device.tabletMax} {
				padding: 0 2.7rem;
			}

			@media ${device.laptopMedium} {
				max-width: 718px;
			}
		}

		.info-box {
			display: flex;
			align-items: center;
			background-color: #300064;
			width: 100%;
			padding: 32px 0;
			margin: 0;
			text-align: center;
			position: relative;

			@media ${device.laptopMedium} {
				display: block;
			}
		}

		.step-info-box {
			flex-wrap: wrap;

			@media ${device.laptopMedium} {
				margin: 0 !important;
    			padding: 12px 27px 22px 27px !important;
			}

			a {
				padding: 16px 0 0;
				&:hover {
					color: #00FFD9;
				}
				&:after {
					@media ${device.mobileMedium} {
						margin-left: 7px
					}
				}
			}

			.o-inner {
				padding: 0 90px;

				@media ${device.laptopMedium} {
					padding: 0;
				}
			}

			.o-inner-wrapper {
				&:first-child {
					p {
						@media ${device.laptopMedium} {
							padding: 0 0 28px 0 !important;
							margin-top: 10px;
							font-family: Gotham, sans-serif;
						}
					}
				}
				.o-container,
				.o-inner,
				.step-info-box {
					padding: 0 !important;
				}
				.o-paragraph {
					@media ${device.ipadLandscapemin} {
						line-height: 1.6;
					}

					span {
						color: #00FFD9;

						&.part-1 {
							color: #fff;
    					text-decoration: underline;
						}
						&.part-2 {
							color: #fff;
							text-decoration: none;
						}

						@media ${device.mobileMedium} {
							line-height: 2.2rem;
						}
					}
				}
			}

			p {
				font-size: 2rem;
				line-height: 2.6rem;
				font-family: 'Gotham-Book', Arial, Helvetica, sans-serif;
				font-weight: 700;
				padding: 0;
    		text-align: center;
				color: #fff;
				margin-bottom: 0;

				@media ${device.laptopMedium} {
					display: inline-block;
					font-size: 1.8rem;
					line-height: 2.2rem !important;
				}

				strong {
					display: initial;
					font-family: 'Gotham, Arial', Helvetica, sans-serif;
					line-height: 3.8rem !important;
					color: #00FFD9;

					@media ${device.laptopMedium} {
						font-size: 1.8rem;
						line-height: 2.2rem !important;
						font-family: 'Gotham', Arial, Helvetica, sans-serif;
					}
				}
			}

			a {
				font-size: 2rem;
				line-height: 2.6rem;
				font-family: 'Gotham-Book';
				font-weight: 700;
				padding-top: 16px;
				text-align: center;
				padding: 15px 0 0;
				text-decoration: underline;
				text-underline-offset: 3px;
				display: flex;
				align-items: center;
				justify-content: center;
				color: #fff;
				position: relative;

				@media ${device.laptopMedium} {
					padding: 0;
					font-size: 1.8rem;
					line-height: 2.2rem !important;
					font-family: 'Gotham', Arial, Helvetica, sans-serif;
					display: block;
				}
			}
		}
	}
`;

export const WhenYoutest = styled.div`
	&.emerging-science-when-you-test {
		.o-inner {
			padding: 0 100px;

			@media ${device.laptopMedium} {
				padding: 0;
			}

			.specialist-infobox.info-box {
				margin-bottom: 120px;
				padding: 32px 80px;

				@media ${device.laptopMedium} {
					margin-bottom: 28px;
					padding: 24px 42px;
				}
			}

			.info-box {
				display: flex;
				align-items: center;
				background-color: #300064;
				padding: 27px 102px;
				position: relative;
				margin: auto;
				width: 100%;
				text-align: center;

				@media ${device.laptopMedium} {
					display: block;
				}

				img {
					position: absolute;
					left: 100px;
					max-width: 100%;
					bottom: 65px;

					@media ${device.laptopMedium} {
						display: block;
    				margin: 0 auto 10px;
						width: 45px;
						left: 0;
						right: 0;
						text-align: center;
						position: unset;
					}
					@media ${device.laptop} {
						bottom: calc(50% - 35px);
					}
				}

				.ask-specialist {
					p {
						padding: 0 0 0 120px;
						font-family: 'Gotham-Book';
						font-weight: 700;
						text-align: left;
						color: #fff;
						line-height: 3.2rem;
						font-size: 2rem;
						margin: 0;

						@media ${device.laptopMedium} {
							padding: 0;
							font-family: 'Gotham';
							line-height: 2rem;
							font-size: 2rem;
							display: inline-block;
							text-align: center;
						}
						&:first-child {
							margin-top: 10px;
						}

						strong {
							color: #00FFD9;
							display: inline-block;
							font-size: 2.7rem;
    					padding-bottom: 12px;
							font-weight: 600;

							@media ${device.laptopMedium} {
								line-height: 2.2rem;
								padding-bottom: 32px;
								font-size: 2rem;
							}
						}
					}

					.ask-specialist-span {
						font-family: 'Gotham-Book', Arial, Helvetica, sans-serif;

						@media ${device.ipadLandscapemin} {
							font-size: 2.2rem;
						}

						p {
							strong {
								padding: 0;
								font-family: 'Gotham-Book', Arial, Helvetica, sans-serif;
								font-weight: 700;
								text-align: left;
								color: #fff;
								line-height: 3.2rem;-
								font-size: 2.2rem;

								@media ${device.laptopMedium} {
									font-family: 'Gotham';
									font-size: 1.6rem;
									line-height: 2rem;
									font-size: 2rem;
									display: inline-block;
									text-align: center;
									margin-top: 10px;
									font-weight: 600;
								}
							}
						}
					}
				}

				.link-right-arrow {
					cursor: pointer;
					background-color: #fff;
					width: 40px;
					height: 40px;
					line-height: 46px;
					text-align: center;
					border-radius: 50%;
					margin: 5px 0 0 28px;

					@media ${device.laptopMedium} {
						display: flex;
						margin: 20px auto 0;
						justify-content: center;
						align-items: center;
						position: initial;
						width: 40px;
						height: 40px;
					}

					.media--image {
						@media ${device.ipadLandscapemin} {
							width: 40px;
						}
					}

					&:hover {
						transform: scale(1.2);
						transition: ease 5ms;
					}

					img {
						display: block;
    				margin: 13px auto;
						max-width: 100%;
						position: static;
						left: 0;

						@media ${device.laptopMedium} {
							margin: 0;
							width: auto;
						}
					}
				}
			}
		}
	}
`;

export const CommonParagraphEmergingScience = styled.div`
	.emerging-science-common-paragraph {
		@media ${device.laptopMedium} {
			margin-top: 100px;
		}

		.o-container {
			max-width: 1208px;
			margin: 0 auto;
			padding: 0 2rem;

			@media ${device.tabletMax} {
				padding: 0 2.7rem;
			}

			@media ${device.laptopMedium} {
				max-width: 718px;
			}

			.o-inner {
				padding: 0 100px;

				@media ${device.laptopMedium} {
					padding: 0;
				}

				h2 {
					font-family: 'Gotham';
					font-size: 4.2rem;
					font-weight: 700;
					line-height: 5rem;
					color: #000000;
					text-align: center;
					margin-bottom: 0;

					@media ${device.laptopMedium} {
						font-size: 2.5rem;
						line-height: 3rem;
					}
				}

				.o-paragraph {
					font-family: 'Gotham';
					padding-top: 40px;
					max-width: 768px;
					margin: auto;
					text-align: center;
					color: #000000;
					font-size: 2.2rem;
					font-weight: 500;
					line-height: 3.2rem;

					@media ${device.laptopMedium} {
						font-size: 1.6rem;
						line-height: 2rem !important;
						padding-top: 23px !important;
						padding-bottom: 20px;
					}

				}
			}
		}
	}

	.right-image-points {
		&.emerging-science--full {
			display: flex;
			flex-wrap: wrap;
			padding: 40px 0 88px;

			@media ${device.laptopMedium} {
				padding: 24px 0 48px;
				padding-top: 0;
			}

			.left-description {
				padding-left: calc((100vw - 1168px) / 2);
				width: 66.6%;
				padding-right: 48px;
				float: left;

				@media ${device.ipadLandscapemin} and ${device.minideskend} {
					width: 56%;
				}
				@media ${device.laptopMedium} {
					width: 100%;
					padding-right: 20px;
					padding-left: 20px;
					margin-top: 0;
				}

				@media ${device.laptop} and ${device.midDesktopMax} {
					padding-right: 150px;
				}
				.o-paragraph {
					p {
						strong {
							font-family: Gotham, sans-serif;
						}
					}
				}

				ul {
					margin-left: 100px;
					position: relative;
					padding-left: 0;
					margin-bottom: 0;

					@media ${device.laptopMedium} {
						margin-left: unset;
					}

					li {
						font-family: 'Gotham';
						align-items: center;
						color: #000000;
						padding: 24px 0 24px 0px;
						display: flex;
						font-weight: 500;
						font-size: 2rem;
						line-height: 2.6rem;

						@media ${device.laptopMedium} {
							padding: 20px 0;
							font-size: 1.6rem;
							line-height: 2rem;
							text-align: center;
    						display: block;
						}

						&:first-child {
							padding-top: 0;

							@media ${device.laptopMedium} {
								padding-top: 20px;
							}
						}
						p {
							margin-bottom: 0;
						}

						.media--image {
							padding-top: 0;
							margin-right: 30px;

							@media ${device.laptopMedium} {
								position: unset;
								left: unset;
								margin-right: 0;
							}

							&:after {
								@media ${device.laptopMedium} {
									content: " ";
									white-space: pre;
									display: block;
								}
							}

							img {
								height: 80px;
								width: auto !important;
								max-width: 35px;

								@media ${device.laptopMedium} {
									padding-top: 0;
									padding-bottom: 0 !important;
									margin: auto;
									display: block;
								}
							}
						}

						.paragraph-wrap {
							p {
								margin: 0;
								font-size: 2rem;
    							line-height: 2.6rem;
								font-weight: 500;

								@media ${device.laptopMedium} {
									font-size: 1.6rem;
    							line-height: 2rem;
									text-align: center;
								}

								strong {
									font-weight: bolder;
								}
							}
						}
					}
					li.mobi-align {
						.media--image {
							margin-right: 43px;

							@media ${device.laptopMedium} {
								margin-right: 0;
							}
						}
						.gatsby-image-wrapper {
							overflow: unset;
						}
					}
				}
			}

			.right-img {
				position: absolute;
				right: 0;
				text-align: center;

				@media ${device.laptopMedium} {
					width: 100%;
					position: unset;
					margin-top: 20px;
				}

				img {
					max-width: 100%;
				}
			}
		}
	}
`;
